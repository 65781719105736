<template>
  <section>
    <b-card>
      <h3 class="mb-2">{{ $t('category.main_informations') }}</h3>
      <validation-observer ref="categoryRules" tag="form">
        <b-row class="match-height">
          <b-col cols="12" lg="7">
            <!--FIELD : name-->
            <app-form-text-input-itn
              v-model="categoryBlank.name"
              :label="$t('category.name')"
              :languages="$store.state.auth.activelang"
              form-name="categories"
            />
            <b-row>
              <b-col cols="12" lg="8">
                <!--FIELD : description-->
                <app-form-text-area-itn
                  v-model="categoryBlank.description"
                  :label="$t('category.description')"
                  :languages="$store.state.auth.activelang"
                  :max-rows="7"
                  :rows="7"
                  form-name="categories"
                  no-resize
                />

              </b-col>
              <b-col cols="12" lg="4">
                <!--FIELD : icon-->
                <b-form-group :label="$t('category.icon.title')" label-for="category-icon">
                  <b-overlay :show="dropZoneLoading" spinner-medium>
                    <app-drop-zone
                      id="category-icon"
                      ref="categoryIcon"
                      :options="dropzoneOptions"
                      :disabled="dropZoneLoading"
                      class="mt-2"
                      @vdropzone-queue-complete="iconUploaded = true"
                      @vdropzone-removed-file="removeCategoryIcon()"
                      @vdropzone-file-added="sendCategoryIcon($event)"
                    />
                  </b-overlay>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12" lg="5">
            <b-form-group :label="$t('category.parent_category')" label-for="parentCategory">
              <div class="border-primary rounded cat-list">
                <categories-tree @category-selected="getCategorySelected" />
              </div>
            </b-form-group>
          </b-col>
        </b-row>
        <h4>{{ $t('category.seo') }}</h4>
        <b-row class="match-height">
          <b-col cols="7">
            <app-form-text-input-itn
              v-model="categoryBlank.seoMetaTitle"
              :label="$t('category.meta_title')"
              :languages="$store.state.auth.activelang"
              form-name="categories"
            />
          </b-col>
          <b-col cols="7">
            <app-form-text-area-itn
              v-model="categoryBlank.seoMetaDescription"
              :label="$t('category.meta_description')"
              :languages="$store.state.auth.activelang"
              :max-rows="5"
              :rows="5"
              form-name="categories"
              no-resize
            />
          </b-col>
        </b-row>
        <b-row class="justify-content-end mr-0 p-1 end-line">
          <b-button type="button" variant="outline-secondary" :to="{ name: 'categories' }" class="mr-50">
            <font-awesome-icon icon="ban" class="mr-50" />
            {{ $t('action.cancel') }}
          </b-button>

          <b-button v-if="!iconUploaded">
            <b-spinner small />
          </b-button>
          <b-button v-else @click="saveCategory">
            <font-awesome-icon icon="save" class="mr-50" />
            {{ $t('action.save') }}
          </b-button>
        </b-row>
      </validation-observer>
    </b-card>
  </section>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import CategoriesTree from '@/views/category/CategoriesTree.vue'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import AppDropZone from '@/components/AppDropZone.vue'
import AppFormTextAreaItn from '@/components/AppFormTextAreaItn.vue'

import { postCategoryRequest } from '@/request/globalApi/requests/categoryRequests'
import { postFileRequest } from '@/request/globalApi/requests/fileRequests'
import ToastificationContent from '@core/components/toastification/ToastificationContent'

export default {
  name: 'CategoryAdd',

  components: {
    CategoriesTree,
    AppFormTextAreaItn,
    AppDropZone,
  },

  mixins: [heightTransition],
  data() {
    return {
      currentLanguage: '',
      categoryBlank: null,
      iconUploaded: true,
      dropZoneLoading: false,
      dropzoneOptions: {
        autoProcessQueue: true,
        url: 'https://localhost',
        dictDefaultMessage: this.$t('category.icon.upload'),
        thumbnailWidth: 250,
        maxFiles: 1,
        maxFilesize: 2000000,
        acceptedFiles: '',
        addRemoveLinks: true,
        clickable: true,
      },
    }
  },
  computed: {
    userLang() {
      return this.$store.state.appConfig.lang
    },
  },
  created() {
    this.setCategoryBlank()
  },
  methods: {
    setCategoryBlank() {
      this.categoryBlank = {
        name: {
          currentLanguage: this.userLang,
          fr: '',
          en: '',
        },
        description: {
          currentLanguage: this.userLang,
        },
        seoMetaTitle: {
          currentLanguage: this.userLang,
        },
        seoMetaDescription: {
          currentLanguage: this.userLang,
        },
        parent: null,
        categoryIconId: null,
        deleteOldCategoryIcon: false,
      }
    },
    getCategorySelected(cats) {
      this.categoryBlank.parent = cats[0].id
    },
    saveCategory() {
      const categoryToSend = JSON.parse(JSON.stringify(this.categoryBlank))
      delete categoryToSend.name.currentLanguage
      delete categoryToSend.description.currentLanguage
      delete categoryToSend.seoMetaTitle.currentLanguage
      delete categoryToSend.seoMetaDescription.currentLanguage

      if (!categoryToSend.description[this.userLang]) {
        categoryToSend.description = {}
      }
      if (!categoryToSend.seoMetaTitle[this.userLang]) {
        categoryToSend.seoMetaTitle = {}
      }
      if (!categoryToSend.seoMetaDescription[this.userLang]) {
        categoryToSend.seoMetaDescription = {}
      }
      postCategoryRequest(categoryToSend).then(() => {
        this.$router.push({ name: 'categories' })
      })
    },
    removeCategoryIcon() {
      this.categoryBlank.categoryIconId = null
      this.categoryBlank.deleteOldCategoryIcon = true
    },
    sendCategoryIcon(file) {
      if (file.size > this.dropzoneOptions.maxFilesize) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t('file.max_file_size_reached'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        this.$refs.categoryIcon.removeFile(file)
        return
      }

      this.dropZoneLoading = true
      this.iconUploaded = false
      const formData = new FormData()
      formData.append('temporary_file', file)
      postFileRequest(formData)
        .then(response => {
          this.categoryBlank.categoryIconId = response.data.id
          this.categoryBlank.deleteOldCategoryIcon = false
        })
        .finally(() => {
          this.iconUploaded = true
          this.dropZoneLoading = false
        })
    },
  },
}
</script>
<style scoped>
.category-view-tree {
  height: 267px;
  max-height: 267px;
  overflow: auto;
}

.dropzone-category {
  height: 181px;
}

.hide-scroll::-webkit-scrollbar {
  display: none;
}
</style>
